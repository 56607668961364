import { container, title } from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";


const WorkGallery = {
  container,
  imgLeft : {
    width: "100%"
  },
  containerWorks: {
    paddingTop: "40px",
    borderTop: "solid 2px #e4e0e0"
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: "#999",
  },
  ...imagesStyle,
  
}

export default WorkGallery;
