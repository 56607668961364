import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";


import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles(styles);

export default function ContactSection() {
  const classes = useStyles();
  const recaptchaRef = React.createRef();
  const [verify, setVerify] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [formMessage, setFormMessage] = useState({name : "", email: "", message : ""});
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [sending, setSending] = useState(false);
 
  function send(event){
    
    event.preventDefault();
    if(verify && privacy && checkForm()){
      setSending(true);
      fetch("/api/send.php?key=4BLm1TbBL82Fq0LWMJKAScdL3odY3t4W", {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formMessage) // body data type must match "Content-Type" header
      })
      .then(response => response.json())
      .then(
        (response) => {
          if(response.success){
            setSendSuccess(true);
            setTimeout(() => {
              setSendSuccess(false);
            }, 15000);
          }
          else{
            setSendError(true);
            setTimeout(() => {
              setSendError(false);
            }, 15000);
          }

          setSending(false);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setSendError(true);
          setTimeout(() => {
            setSendError(false);
          }, 15000);

          setSending(false);
        }
      )
    }
  }

  function handleFormChange(val){
    var change = formMessage;
    change[val.target.id] = val.target.value;
    
    setFormMessage(change);
    
    val.target.id == 'name' && setNameError(formMessage.name == "");
    val.target.id == 'email' && setEmailError(formMessage.email == "");
    val.target.id == 'message' && setMessageError(formMessage.message == "");
  }

  function checkForm(){
    
    setNameError(formMessage.name == "");
    setEmailError(formMessage.email == "");
    setMessageError(formMessage.message == "");
    
    return (!nameError && !emailError && !messageError)

  }


  return (
    <div id="contact" className={classes.container}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Contattami</h2>
          <h4 className={classes.description}>
            Divide details about your product or agency work into parts. Write a
            few lines about each one and contact us about any further
            collaboration. We will responde get back to you in a couple of
            hours.
          </h4>
          <form onSubmit={(event) => send(event)}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Nome"
                  id="name"
                  error={nameError}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange : (val) => {handleFormChange(val)}
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Email"
                  id="email"
                  error={emailError}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange : (val) => {handleFormChange(val)},
                    type:"email"
                  }}
                />
              </GridItem>
              <CustomInput
                labelText="Messaggio"
                id="message"
                error={messageError}
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                inputProps={{
                  multiline: true,
                  rows: 5
                }}
                inputProps={{
                  onChange : (val) => {handleFormChange(val)}
                }}
              />
              <GridItem xs={12} sm={12} md={12}>
                <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => setPrivacy(!privacy)}
                        checked={privacy}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label, root: classes.labelRoot }}
                    label={<span><span style={{color: "red"}}>*</span> Acconsento al trattamento dei dati personali ai sensi degli articoli 13-14 del GDPR 2016/679. <a href="https://www.iubenda.com/privacy-policy/25687784" target="_blank" title="Privacy Policy ">Privacy Policy</a></span>}
                  />
              </GridItem>
              <GridItem xs={12} sm={8} md={8}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6Lev04caAAAAAOZ57USGn6i2AQFucQkUIIkRToNI"
                  onChange={(value) => setVerify(value!="")}
                  onExpired={() => setVerify(false)}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <Button 
                  color="success" 
                  disabled={!verify || !privacy || sending} 
                  type="submit" 
                  loading={sending}
                >
                  Invia Messaggio
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      
      {
        sendSuccess &&
        <SnackbarContent
          message={
            <span>
              <b>MESSAGGIO INVIATO:</b> Verrai riconttatto il prima possibile.
            </span>
          }
          close
          color="success"
          icon={Check}
        />
      }
      
      {
        sendError &&
        <SnackbarContent
          message={
            <span>
              <b>ERRORE:</b> Abbiamo riscontrato un problema durante l'invio. Riprova più tardi, grazie.
            </span>
          }
          close
          color="danger"
          icon="info_outline"
        />
      }
    </div>
  );
}
