/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { CloudDownload, Domain, Extension, Museum, Work, Contacts } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import presentazione from "assets/img/gwladys/presentazione_gwladys_martini.pdf";


const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="I MIEI LAVORI"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Work}
          dropdownList={[
            <Link to="/museum" className={classes.dropdownLink}>
              <Museum style={{marginRight: "5"}} />Musei
            </Link>,
            <Link to="/games" className={classes.dropdownLink}>
              <Extension style={{marginRight: "5"}}/>Giochi Formativi
            </Link>,
            <Link to="/corporate" className={classes.dropdownLink}>
              <Domain style={{marginRight: "5"}}/>Corporate Image
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href={presentazione}
          color="transparent"
          target="_blank"
          className={classes.navLink}
          download
        >
          <CloudDownload className={classes.icons} /> Download
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}>

        <Link smooth to="/#contact" className={classes.navLink} style={{textDecoration: "none"}}>
          <Contacts className={classes.icons} /> Contatti
        </Link>

        
        
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/gwladys.martini"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      
    </List>
  );
}
