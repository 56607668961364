import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import ContactSection from "views/Components/Sections/ContactSection.js"

import profile from "assets/img/gwladys/avatar.jpg";
import main from "assets/img/gwladys/main.jpg";

import mostra1 from "assets/img/gwladys/mostre/mostra_1.jpg";
import mostra2 from "assets/img/gwladys/mostre/mostra_2.jpg";
import mostra3 from "assets/img/gwladys/mostre/mostra_3.jpg";
import mostra4 from "assets/img/gwladys/mostre/mostra_4.jpg";
import mostra5 from "assets/img/gwladys/mostre/mostra_5.jpg";
import mostra6 from "assets/img/gwladys/mostre/mostra_6.jpg";
import mostra7 from "assets/img/gwladys/mostre/mostra_7.jpg";
import mostra8 from "assets/img/gwladys/mostre/mostra_8.jpg";
import mostra9 from "assets/img/gwladys/mostre/mostra_9.jpg";
import mostra10 from "assets/img/gwladys/mostre/mostra_10.jpg";


import giochi1 from "assets/img/gwladys/giochi/giochi_1.jpg";
import giochi2 from "assets/img/gwladys/giochi/giochi_2.jpg";
import giochi3 from "assets/img/gwladys/giochi/giochi_3.jpg";
import giochi4 from "assets/img/gwladys/giochi/giochi_4.jpg";

import corporate1 from "assets/img/gwladys/corporate/corporate_1.jpg";
import corporate2 from "assets/img/gwladys/corporate/corporate_2.jpg";
import corporate3 from "assets/img/gwladys/corporate/corporate_3.jpg";
import corporate4 from "assets/img/gwladys/corporate/corporate_4.jpg";
import corporate5 from "assets/img/gwladys/corporate/corporate_5.jpg";
import corporate6 from "assets/img/gwladys/corporate/corporate_6.jpg";
import corporate7 from "assets/img/gwladys/corporate/corporate_7.jpg";
import corporate8 from "assets/img/gwladys/corporate/corporate_8.jpg";


import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { Extension, Museum, Domain } from "@material-ui/icons";


const useStyles = makeStyles(styles);

export default function HomePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand="Gwladys Martini"
        brandLink="/"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax image={main}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.mainTitle}>SERVIZI PER L’ARTE</h1>
              <h4 className={classes.subtitle}>comunicazione, grafica e allestimento museale</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Gwladys Martini</h3>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                Amo il mondo delle mostre e le seguo a 360°, dalla curatela, all’allestimento, alla logistica, ai cataloghi.
                Dopo aver studiato architettura e restauro, mi sono dedicata per anni all’allestimento museale, in particolar modo ho curato la parte grafica, scegliendo colori, forme e lettering degli apparati didattici (didascalie, testi espografici, titoli, ecc...), studiando forma e leggibilità e curando l’esecuzione e l’installazione.
                Ho progettato piani di allestimento in collaborazione con importanti curatori e ho ideato e disegnato gli esecutivi di teche e sistemi espositivi, studiando con gli illuminotecnici le luci migliori per valorizzare le opere.
                Come art director mi sono occupata di creare presentazioni per nuovi prodotti, sia come layout, che come contenuti. In alcuni casi ho anche collaborato alla progettazione dei prodotti stessi, cercando nuovi materiali per soluzioni innovative e prendendo i contatti con i possibili fornitori.
                Tra i miei clienti più importanti ci sono stati la Fondazione Palazzo Bricherasio di Torino, la Fondazione Cosso di San Secondo di Pinerolo, la Regione Piemonte, l’Officina della Scrittura di Torino, Museo Nazionale del Cinema di Torino, TEDxTorino, SEEYOUSOUND Music Film Festival e l’Associazione Culturale Acribia.
              </p>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="info"
                  tabs={[
                    {
                      tabButton: "Musei",
                      tabIcon: Museum,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={mostra1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={mostra2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={mostra3}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={mostra9}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={mostra4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={mostra5}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={mostra6}
                              className={navImageClasses}
                            /><img
                              alt="..."
                              src={mostra10}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={mostra7}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={mostra8}
                              className={navImageClasses}
                            />
                            
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "Giochi Formativi",
                      tabIcon: Extension,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={giochi1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={giochi2}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={giochi3}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={giochi4}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "corporate image",
                      tabIcon: Domain,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={corporate1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={corporate2}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={corporate4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={corporate5}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={corporate6}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={corporate7}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={corporate8}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={corporate3}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <ContactSection />
      </div>
      <Footer />
    </div>
  );
}
