import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import test from "assets/img/gwladys/mostre/mostra_1.jpg";
import styles from "assets/jss/workGallery.js";
import ImageGallery from 'react-image-gallery';

const useStyles = makeStyles(styles);



export default function WorkGallery(props) {
    const classes = useStyles();
    const { items } = props;

    return (
        <div className={classes.containerWorks}>
            {
            items.map((item, key) => {
                return (
                    <GridContainer style={{paddingBottom: "20px"}} key={key}>
                        <GridItem xs={4} sm={4} md={4} >
                            <ImageGallery 
                                style={{zIndex: "1200"}}
                                items={item.gallery}
                                showThumbnails={false}
                                showPlayButton={false}
                                useBrowserFullscreen={false}
                                onScreenChange={(fullscreen) => {
                                    document.getElementsByTagName("header")[0].style.display = fullscreen ? "none" : "flex";
                                }}
                            />

                        </GridItem>
                        <GridItem xs={8} sm={8} md={8} >
                            <h5>{item.title}</h5>
                            <p className={classes.description}>{item.description}</p>
                        </GridItem>
                    </GridContainer>
                )
            })
            }
        </div>
    )
}