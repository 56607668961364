import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import useGoogleAnalytics from "components/Analytics/useGoogleAnalytics";
import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import Museums from "views/Works/Museums.js";
import HomePage from "views/Home/HomePage.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import CookieBanner from 'react-cookie-banner';

var history = createBrowserHistory();

function App() {
  

  function Routes() {
    useGoogleAnalytics()
   
    return (
      <Switch>
        {/* <Route exact path="/landing-page" component={LandingPage} />
        <Route exact path="/profile-page" component={ProfilePage} />
        <Route exact path="/login-page" component={LoginPage} />
        <Route exact path="/components" component={Components} /> */}
        <Route exact path="/museum" component={Museums} />
        <Route exact path="/games" component={Museums} />
        <Route exact path="/corporate" component={Museums} />
        <Route exact path="/" component={HomePage} />
      </Switch>
    )
  }



  return (
    <div>
      <CookieBanner
          message="Noi e alcuni partner selezionati utilizziamo cookie o tecnologie simili come specificato nella Privacy, chiudendo questo banner o continuando la navigazione acconsenti l'utilizzo di essi."
          onAccept={() => {}}
          cookie="user-has-accepted-cookies" 
          dismissOnScroll={true}
          buttonMessage={"Ho capito"}
          link={<a href="https://www.iubenda.com/privacy-policy/25687784" target="_blank" title="Privacy Policy ">Cookie Policy</a>}
          styles={{
            banner: {position: "fixed", textAlign: "left", paddingLeft: "10px", height: ""},
            message: { fontSize: "1.5vw", lineHeight: "3vh" },
            button: {top: "", marginTop: ""}
          }}
      />

      <BrowserRouter>
        <Routes/>
      </BrowserRouter>
      
      
    </div>
  );
  

}

export default App;